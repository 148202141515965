import React, { useMemo } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { paypalPayment, paypalPaymentSuccess } from "../../../../../service";
import { makeGamesObject, verifyGoogleCaptcha } from "../../../../utils";
import { useNavigate } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const PaypalButton = ({
  amount,
  disabled,
  campaignData,
  email,
  team_id,
  setIsLoading,
  charities,
  name,
}: any) => {
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const createOrder = async () => {
    const { data }: any = await paypalPayment({ amount });
    return data.orderID;
  };

  const handleSuccess = async (details: any) => {
    if (details.status === "COMPLETED" && details?.id) {
      setIsLoading(true);
      const totalAmount = Number(details?.purchase_units[0]?.amount?.value);
      const games = await makeGamesObject(
        campaignData?.gamesDetails,
        totalAmount
      );
      const response: any = await paypalPaymentSuccess({
        paymentId: details?.id,
        amount: totalAmount,
        campaign_id: campaignData?._id,
        email: email,
        games,
        team_id,
        charities,
        name,
      });
      setIsLoading(false);
      navigate(`/thankyou/${response?.donationId}`);
    }
  };

  const paypalOptions = useMemo(
    () => ({
      clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID as string,
      components: "buttons",
      currency: "AUD",
    }),
    []
  );

  return (
    <PayPalScriptProvider options={paypalOptions}>
      <PayPalButtons
        key={amount + email + team_id + name}
        style={{
          shape: "rect",
          layout: "horizontal",
          height: 50,
          tagline: false,
        }}
        createOrder={() => createOrder()}
        onApprove={(_, actions: any) => {
          return actions.order.capture().then(async (details: any) => {
            if (!executeRecaptcha) {
              return;
            }
            const token = await executeRecaptcha("submit");
            const isCaptchaValid = await verifyGoogleCaptcha(token);
            if (!isCaptchaValid) {
              navigate("/error/404");
              return;
            }
            handleSuccess(details);
          });
        }}
        onError={() => {}}
        disabled={disabled ? true : false}
      />
    </PayPalScriptProvider>
  );
};

export default PaypalButton;
