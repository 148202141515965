import React, { useState } from "react";
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import clsx from "clsx";
import {
  loadStripe,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";
import { makeGamesObject, verifyGoogleCaptcha } from "../../../../utils";
import { makePayment } from "../../../../../service";
import { useNavigate } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
const CheckoutForm = ({
  setOpen,
  values,
  amount,
  campaignData,
  charities,
}: {
  setOpen: (_arg1: boolean) => void;
  values: any;
  amount: any;
  campaignData: any;
  charities: any;
}) => {
  const [loading, setLoading] = useState(false);
  const elements: any = useElements();
  const stripe: any = useStripe();
  const [paymentError, setPaymentError]: any = useState(null);
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [validCard, setValidCard] = useState<any>({
    cardNumber: null,
    cardCvc: null,
    cardExpiry: null,
  });
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const { cardNumber, cardExpiry, cardCvc } = validCard;
    if (cardNumber === null || cardExpiry === null || cardCvc === null) {
      setValidCard({
        cardNumber: cardNumber ? null : "Card number is required",
        cardExpiry: cardExpiry ? null : "Expiry date is required",
        cardCvc: cardCvc ? null : "CVV is required",
      });
      return;
    }
    if (cardNumber || cardExpiry || cardCvc) return;
    setLoading(true);
    if (!executeRecaptcha) {
      return;
    }
    const token = await executeRecaptcha("submit");
    const isCaptchaValid = await verifyGoogleCaptcha(token);
    if (!isCaptchaValid) {
      navigate("/error/404");
      return;
    }
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });
    if (!error) {
      const games = await makeGamesObject(
        campaignData?.gamesDetails,
        Number(amount)
      );
      const response = await makePayment({
        amount: Number(amount),
        paymentMethodId: paymentMethod?.id,
        campaign_id: campaignData?._id,
        email: values?.email,
        name: values?.name,
        team_id: values?.team,
        games,
        charities,
      });
      if (response?.donationId) {
        setOpen(false);
        navigate(`/thankyou/${response?.donationId}`);
      } else {
        setPaymentError("Payment Failed !");
      }
    }
    setLoading(false);
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-6 mb-[10px]">
          <div className="space-y-2 w-full">
            <label className="font-poppins-500 text-dark_gray text-[16px] leadng-[24px]">
              Card Number
            </label>
            <div
              className={clsx(
                "border-[1px] border-[#E6EAEE] rounded-[5px] px-2 py-3 flex items-center space-x-2",
                {
                  "border-inValid": validCard.cardNumber,
                  "border-valid":
                    !validCard.cardNumber && validCard.cardNumber !== null,
                }
              )}
            >
              <CardNumberElement
                className="flex-1"
                onChange={(e: StripeCardNumberElementChangeEvent) =>
                  setValidCard({ ...validCard, cardNumber: e?.error?.message })
                }
              />
            </div>
            {validCard.cardNumber && (
              <span className="text-red-500 font-poppins-500 text-[14px] mt-2">
                {validCard.cardNumber}
              </span>
            )}
          </div>
          <div className="flex gap-4 w-full ">
            <div className="space-y-2 w-[60%]">
              <label className="font-poppins-500 text-dark_gray text-[16px] leadng-[24px]">
                Expiry
              </label>
              <div
                className={clsx(
                  "border-[1px] border-[#E6EAEE] rounded-[5px] px-2 py-3",
                  {
                    "border-inValid": validCard.cardExpiry,
                    "border-valid":
                      !validCard.cardExpiry && validCard.cardExpiry !== null,
                  }
                )}
              >
                <CardExpiryElement
                  onChange={(e: StripeCardExpiryElementChangeEvent) =>
                    setValidCard({
                      ...validCard,
                      cardExpiry: e?.error?.message,
                    })
                  }
                />
              </div>
              {validCard.cardExpiry && (
                <span className="text-red-500 font-poppins-500 text-[14px] mt-2">
                  {validCard.cardExpiry}
                </span>
              )}
            </div>
            <div className="space-y-2 w-[40%]">
              <label className="font-poppins-500 text-dark_gray text-[16px] leadng-[24px]">
                CVV
              </label>
              <div
                className={clsx(
                  "border-[1px] border-[#E6EAEE] rounded-[5px] px-2 py-3",
                  {
                    "border-inValid": validCard.cardCvc,
                    "border-valid":
                      !validCard.cardCvc && validCard.cardCvc !== null,
                  }
                )}
              >
                <CardCvcElement
                  onChange={(e: StripeCardCvcElementChangeEvent) =>
                    setValidCard({ ...validCard, cardCvc: e?.error?.message })
                  }
                />
              </div>
              {validCard.cardCvc && (
                <span className="text-red-500 font-poppins-500 text-[14px] mt-2">
                  {validCard.cardCvc}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <button
            className="font-poppins-500 rounded-lg sm:w-[400px] w-[250px] px-6 py-5 hover:bg-[#8E56EC] text-secondary_1 bg-gradient-to-r from-liner_1 to-liner_2"
            type="submit"
            onClick={handleSubmit}
          >
            {loading ? "Processing..." : "Pay"}
          </button>
        </div>
        {paymentError && (
          <span className="text-red-500 font-poppins-400">{paymentError}</span>
        )}
      </div>
    </form>
  );
};
const StripePromise = loadStripe(process.env.REACT_APP_PUBLISH_KEY as string);
const StripePaymentForm = ({
  setOpen,
  values,
  amount,
  campaignData,
  charities,
}: {
  setOpen: (_arg1: boolean) => void;
  values: any;
  amount: number;
  campaignData: any;
  charities: any;
}) => {
  return (
    <Elements stripe={StripePromise}>
      <CheckoutForm
        setOpen={setOpen}
        values={values}
        amount={amount}
        campaignData={campaignData}
        charities={charities}
      />
    </Elements>
  );
};
export default StripePaymentForm;
