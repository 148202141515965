import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { detect, supportedLangs, filter } from "curse-filter";
import * as Yup from "yup";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Slider from "@mui/material/Slider";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import Modal from "../../../../components/Modal";
import StripePaymentForm from "./CardPaymentForm";
import PaypalButton from "./PaypalButton";
import Loader from "../../../../components/Loader";
import { verifyEmail } from "../../../../utils";

const PaymentForm = ({ campaignData, error, amount }: any) => {
  const [open, setOpen] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [updatedCharities, setUpdatedCharities]: any = useState([]);
  const [email, setEmail] = useState("");
  const [teams, setTeams] = useState([]);
  const [charity, setCharity] = useState<
    Array<{ id: string; name: string; discount: number }>
  >([]);
  const [team, setTeam] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = charity.map((item: any) => item.discount);
  const [charitiesDiscount, setCharitiesDiscount]: any =
    useState(initialValues);
  const [discounts, setDiscounts] = useState<
    Array<{ id: string; price: number; discount: number }>
  >([]);

  useEffect(() => {
    if (campaignData) {
      const options = campaignData?.teamsDetails?.map((i: any) => {
        return { value: i?._id, label: i?.name };
      });
      setTeams(options);
    }
  }, [campaignData]);

  useEffect(() => {
    if (campaignData && campaignData.charitiesDetails) {
      const values = campaignData.charitiesDetails.map((item: any) => {
        const percentageValue = item.discount;
        const price = (percentageValue / 100) * amount;
        return {
          id: item._id,
          name: item.name,
          discount: percentageValue,
          price,
        };
      });
      setCharity(values);
      setCharitiesDiscount(values.map((item: any) => item.discount));
      setDiscounts(
        values.map((item: any) => ({
          id: item.id,
          price: (item.discount / 100) * amount,
          discount: item.discount,
        }))
      );
      error || !verifyEmail(email)
        ? setPaymentType("")
        : setPaymentType("card");
    }
  }, [campaignData, amount]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const handleSliderChange = (index: number, newValue: any) => {
    const newValues = [...updatedCharities];
    newValues[index] = newValue;
    const remainingPercentage = 100 - newValue;

    const totalOtherValues = updatedCharities.reduce(
      (acc: any, val: any, i: any) => (i !== index ? acc + val : acc),
      0
    );

    newValues.forEach((val: any, i: any) => {
      newValues[i] =
        i !== index
          ? (newValues[i] / totalOtherValues) * remainingPercentage
          : val;
    });

    const updatedDiscounts = charity.map((item, i) => ({
      id: item.id,
      price: (newValues[i] / 100) * amount,
      discount: newValues[i],
    }));

    setDiscounts(updatedDiscounts);
    setCharitiesDiscount([...newValues]);
  };

  useEffect(() => {
    setUpdatedCharities(charity.map((item: any) => item.discount));
  }, [charity]);

  const renderBtn = (isBtnTrue: any, btnName: string, emailError: string) => (
    <button
      className={`flex justify-center border-[2px] border-light_low_gray px-7 py-4 rounded-lg ${
        isBtnTrue && "border-primary_1 outline-none"
      }`}
      onClick={() => {
        if (emailError || error || !email) return;
        setOpen(true);
        setPaymentType(btnName);
      }}
    >
      <img src={`./images/${btnName}.png`} alt={btnName} />
    </button>
  );

  return (
    <>
      <Formik
        initialValues={{ email: "", name: "" }}
        onSubmit={() => {}}
        validationSchema={validationSchema}
      >
        {({ values, touched, errors, setFieldValue }) => (
          <Form>
            <div className="flex flex-col gap-8">
              <div className="flex flex-col gap-4">
                <Field
                  as={TextField}
                  id="email"
                  name="email"
                  label="Contact Email (Required)"
                  variant="standard"
                  fullWidth
                  error={!!errors.email && touched.email}
                  helperText={touched.email && errors.email}
                  className="!font-poppins-500"
                  value={email}
                  onChange={(e: any) => {
                    verifyEmail(e.target.value)
                      ? setPaymentType("card")
                      : setPaymentType("");
                    setEmail(e.target.value);
                    setFieldValue("email", e.target.value);
                  }}
                />
                <Field
                  as={TextField}
                  id="name"
                  name="name"
                  label="Name/Nickname/Alias (Optional)"
                  variant="standard"
                  fullWidth
                  className="!font-poppins-500"
                  inputProps={{ maxLength: 40 }}
                  onChange={(e: any) => {
                    let value = e.target.value;
                    if (detect(value, { lang: supportedLangs })) {
                      value = filter(value, { lang: supportedLangs });
                    }
                    setFieldValue("name", value);
                  }}
                />
                <Field as={FormControl} variant="standard" fullWidth>
                  <InputLabel id="team-label">
                    Pick a Team (Optional)
                  </InputLabel>
                  <Select
                    labelId="team-label"
                    id="team"
                    name="team"
                    value={team}
                    label="Team"
                    onChange={(e: any) => {
                      setTeam(e.target.value);
                      setFieldValue("team", e.target.value);
                    }}
                  >
                    {teams?.map((option: any, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Field>
              </div>
              <div className="flex flex-col gap-4">
                <span className="text-[25px] text-primary_2 font-poppins-500">
                  Adjust Donation
                </span>
                <div className="flex flex-col gap-4">
                  {charity?.map((item, index) => {
                    return (
                      <div
                        className="grid grid-cols-12 lg:gap-4 gap-8 items-center"
                        key={index}
                      >
                        <span className="text-[17px] md:col-span-4 col-span-12 text-secondary_2 font-poppins-400">
                          {item?.name}
                        </span>
                        <div className="md:col-span-6 col-span-6 sxs:col-span-8">
                          <Slider
                            size="small"
                            value={charitiesDiscount[index]}
                            max={100}
                            aria-label="Small"
                            valueLabelDisplay="auto"
                            onChange={(e, newValue) =>
                              handleSliderChange(index, newValue as number)
                            }
                            sx={{
                              "& .MuiSlider-track": {
                                backgroundColor: "#6E4AEF",
                              },
                              "& .MuiSlider-rail": {
                                backgroundColor: "#D9D9D9",
                              },
                              "& .MuiSlider-thumb": {
                                backgroundColor: "#6E4AEF",
                              },
                            }}
                          />
                        </div>

                        <div className="md:col-span-2 col-span-3 sxs:col-span-5">
                          <Input
                            id="standard-adornment-amount"
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                            value={(
                              (charitiesDiscount[index] * amount) /
                              100
                            ).toFixed(2)}
                            disabled
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="flex flex-col gap-8">
                <span className="text-[25px] text-primary_2 font-poppins-500">
                  Pay with Card or Paypal
                </span>
                <div className="payment-open flex justify-between gap-6 items-center">
                  <div className="w-full">
                    {renderBtn(
                      paymentType === "card",
                      "card",
                      errors.email as string
                    )}
                  </div>

                  <div
                    className="w-full flex justify-center"
                    onClick={() => setPaymentType("paypal")}
                  >
                    <PaypalButton
                      disabled={!verifyEmail(email) || open || error}
                      amount={amount}
                      email={email}
                      campaignData={campaignData}
                      team_id={team}
                      setIsLoading={setIsLoading}
                      charities={discounts}
                      name={values.name}
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-center">
                <Modal open={open} setOpen={setOpen}>
                  <StripePaymentForm
                    setOpen={setOpen}
                    values={values}
                    amount={amount}
                    campaignData={campaignData}
                    charities={discounts}
                  />
                </Modal>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {isLoading && <Loader />}
    </>
  );
};

export default PaymentForm;
